import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { walletStore } from '@/stores/wallet-store'
import { computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'

export class InviteByLinkViewModel {
  @observable link: any = undefined
  @observable accepting = false

  constructor() {
    this.fetchInviteLink()
  }

  @asyncAction *checkMembership(inviteLink) {
    try {
      if (walletStore.userProfile?._id) {
        const membership = yield apiService.userDao.find({
          dao: inviteLink.dao.id,
          profile: walletStore.userProfile?._id,
        })
        if (membership.length) appProvider.router.push(`/community-organize/dao-detail/${inviteLink.dao.id}`)
      }
    } catch (error) {}
  }

  @asyncAction *fetchInviteLink() {
    try {
      const inviteLinkId = appProvider.router.currentRoute.params.id
      const inviteLink = yield apiService.inviteLink.findOne(inviteLinkId)
      yield this.checkMembership(inviteLink)
      this.link = inviteLink
    } catch (error) {}
  }

  @asyncAction *acceptInvite() {
    try {
      this.accepting = true
      if (this.link) {
        const res = yield apiService.daos.acceptInviteLink({ inviteLinkId: this.link.id })
        if (res) {
          appProvider.router.push(`/community-organize/dao-detail/${this.link.dao.id}`)
        }
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.accepting = false
    }
  }

  @computed get isActive() {
    if (this.link.noLimit) return true
    if (this.link?.expiredTime) return moment(this.link?.expiredTime).isAfter(moment())
    return false
  }

  @computed get isInvitationActive() {
    return this.link.dao.invitation
  }
}
