





















































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { InviteByLinkViewModel } from '@/modules/community-organize/viewmodels/invite-by-link-viewmodel'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Provide() vm = new InviteByLinkViewModel()
}
